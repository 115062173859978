// extracted by mini-css-extract-plugin
export var lbContainerOuter = "q_rs";
export var lbContainerInner = "q_rt";
export var movingForwards = "q_rv";
export var movingForwardsOther = "q_rw";
export var movingBackwards = "q_rx";
export var movingBackwardsOther = "q_ry";
export var lbImage = "q_rz";
export var lbOtherImage = "q_rB";
export var prevButton = "q_rC";
export var nextButton = "q_rD";
export var closing = "q_rF";
export var appear = "q_rG";